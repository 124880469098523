import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";


const Account = () => {
  const { user, logout } = UserAuth();
  const navigate = useNavigate();
  const [usersInfo, setUsersInfo] = useState("");
  const [currentUser, setCurrentUser] = useState("");

  useEffect(() => {
    callAPI();
    console.log("INFO:", usersInfo);
  }, []);

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
      console.log("You are logged out");
    } catch (e) {
      console.log(e.message);
    }
  };

  function callAPI() {
    console.log("Called API");
    fetch(`http://localhost:3001/api/users/${user && user.email}`)
      .then((res) => res.text())
      .then((res) => setUsersInfo(JSON.parse(res).response))
      .then(console.log("USERS> ", usersInfo))
      .then(getUser());
  }

  function getUser() {
    console.log("Called getUser() - User Count: ", usersInfo.length);
    setCurrentUser("");
    for (let i = 0; i < usersInfo.length; i++) {
      const userEmail = usersInfo[i].Email;
      console.log("Searching: ", userEmail);
      if (userEmail.toLowerCase() === user.email.toLowerCase()) {
        setCurrentUser(usersInfo[i]);
        console.log("User found and set.");
        break;
      }
    }
  }

  if (currentUser !== null || currentUser !== " ") {
    return (
      <div className="max-w-[600px] h-screen mx-auto py-24">
        <div className="py-10"></div>
        <h1 className="border rounded-md border-orange-500 bg-orange-600 hover:bg-orange-500 w-full p-4 text-white">
          Account Profile
        </h1>
        <p className="text-white p-4">
          Name: {user && currentUser.FirstName} {user && currentUser.LastName}
        </p>
        <p className="text-white p-4">
          Username: {user && currentUser.UserName}
        </p>
        <p className="text-white p-4">Email: {user && user.email}</p>
        <p className="text-white p-4">
          Total Logins: {user && currentUser.Logins}
        </p>
        <p className="text-white p-4">
          {currentUser.Thor === "YES" ? <p>Admin: Yes</p> : null}
        </p>
        <p className="text-white p-4">
          Last Login: {user && currentUser.LastLogin}
        </p>
        <div className="flex items-end justify-end py-20">
          <button
            onClick={handleLogout}
            className="border rounded-md border-orange-500 bg-orange-600 hover:bg-orange-500 p-4 my-2 text-white"
          >
            Logout
          </button>
        </div>
      </div>
    );
  } else {
    return <div className="h-full w-full bg-blue-700">data is here</div>;
  }
};

export default Account;
