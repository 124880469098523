import React from "react";
import GameCenterIMG1 from "../assets/game_center_rnd1.png";
import YGI from "../assets/ygi.png";
import BINGO from "../assets/bingo.png";
import ODPT from "../assets/od-trivia.png";
import "../index.css";

const GameCenter = () => {
  return (
    <div
      name="gamecenter"
      className="w-full h-full items-center flex justify-center p-5"
    >
      <div className="">
        <div className="max-w-[1000px] h-full">
          <h1 className="text-2xl mt-4 text-orange-600 font-bold inine border-b-4 border-white-500">
            ORANGE DOOR Game Center
          </h1>
          <h2 className="text-base text-gray-300 py-4 ">
            We provide a range of Gameshows, with easy access to download &
            control on your system. We offer multi week trials & free shows if
            you are wanting to explore new options for entertainment in your
            venue.
          </h2>
        </div>
        <div className="py-4"></div>
        <div className="max-w-[1000px] h-full flex items-center justify-center">
          <img
            className="rounded-md shadow-lg shadow-orange-600"
            src={GameCenterIMG1}
            alt=""
          />
        </div>
        <div className="max-w-[800px] py-4 grid grid-cols-3 mx-auto items-center justify-center cursor-pointer">
          <img
            className="img-hover mx-auto mt-4 rounded-md hover:duration-300"
            src={YGI}
            alt=""
          />
          <img
            className="img-hover mx-auto mt-4 rounded-md hover:duration-300 "
            src={ODPT}
            alt=""
          />
          <img
            className="img-hover mx-auto mt-4 rounded-md hover:duration-300 "
            src={BINGO}
            alt=""
          />
        </div>
        <div className="max-w-[1000px] flex items-center justify-center">
          <h2 className="text-base text-gray-300 py-4">
            To find out more information about our gameshows please contact us
            and we can help show you the benefit of running a gameshow in your
            venue.
          </h2>
        </div>
      </div>
    </div>
  );
};

export default GameCenter;
