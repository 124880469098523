import React, { useState, useEffect } from "react";
import Axios from "axios";

const GetShipments = () => {
  const [comments, setComments] = useState([]);
  useEffect(() => {
    fetchComments();
  }, []);
  useEffect(() => {
    //console.log(comments);
  }, [comments]);
  const fetchComments = async () => {
    const response = await Axios(
      "https://odme-backend.herokuapp.com/api/shipments"
      );
    setComments(response.data.response);
  };
  return comments;
};

export default GetShipments;
