import React from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-scroll";

const Home = () => {
  return (
    <div name="home" className="w-full h-screen bg-[#0a192f]">
      {/* container */}
      <div className="max-w-[1000px] min-w-[200px] mx-auto px-8 flex flex-col justify-center h-full">
        <p className="text-orange-600 font-bold">Hello, and welcome to</p>
        <p className="text-4xl sm:text-7xl font-bold text-[#ccd6f6]">
          ORANGE DOOR<p className="text-5xl inline text-orange-600">.ME</p>
        </p>
        <h2 className="text-2xl sm:text-5xl font-bold text-[#8892b0]">
          ENTERTAIN <p className="text-orange-600 inline">:</p> ADVERTISE{" "}
          <p className="text-orange-600 inline">:</p> ENGAGE
        </h2>
        <p className="text-gray-300 py-4 max-w-[700px]">
          The Orange Door Music System is the last Mutlimedia Entertainment
          system you will ever need! With the abillity to engage your guests
          with the latest in Music Video content, as well as increasing revenue
          while advertising your own products on your own screens, bar game
          shows and more!
        </p>
        {/*
        <div>
          <button className="text-white group border-2 px-6 py-3 my-2 flex items-center shadow-lg shadow-orange-600 hover:shadow-white-900 hover:bg-orange-600 hover:border-orange-600">
            <Link to="work" className="group" smooth={true} duration={500}>
              See what our Customers have to say!
            </Link>
            <span className="group-hover:rotate-90 duration-300">
              <HiArrowNarrowRight className="ml-3 group">
                <Link
                  to="work"
                  className="group"
                  smooth={true}
                  duration={500}
                ></Link>
              </HiArrowNarrowRight>
            </span>
          </button>
        </div>
  */}
      </div>
    </div>
  );
};

export default Home;
