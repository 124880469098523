import React, { useState, useEffect } from "react";
import GetReturns from "../context/GetReturns";
import { Container, Table, InputGroup } from "reactstrap";
import Form from "react-bootstrap/Form";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";

function Returns() {
  const [search, setSearch] = useState("");
  var x = GetReturns();
  if (x === null || x === " ") {
    console.log("returned null");
    return (
      <div className="w-full h-full py-[300px] text-white">
        <p>nothing.</p>;
      </div>
    );
  } else {
    return (
      <div className="py-40 w-full h-full max-w-[1000] text-center">
        <Container>
          <h1 className="text-white text-center mt-4">Returns List</h1>
          <Form>
            <InputGroup className="my-3">
              <Form.Control
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search Returns"
              />
            </InputGroup>
          </Form>
          <Table striped bordered hover>
            <thead>
              <tr className="text-white">
                <th>Serial Number</th>
                <th>Venue</th>
                <th>Status</th>
                <th className="">Tracking Number</th>
              </tr>
            </thead>

            <tbody>
              {x
                .filter((item) => {
                  return search.toLowerCase() === ""
                    ? item && item.Active.toLowerCase() === "active" || item && item.Active.toLowerCase() === "in transit"
                    : item.Venue.toLowerCase().includes(search.toLowerCase()) ||
                    item.SerialNumber.toLowerCase().includes(search.toLowerCase());
                })
                .map((item) => (
                  <tr
                    key={item.id}
                    className="text-white bg-gray-400"
                    class="even:bg-white odd:bg-gray-400"
                  >
                    <td>{item.SerialNumber}</td>
                    <td>{item.Venue}</td>
                    
                    <td>{item.Active.toUpperCase()}</td>
                    <td>
                      <a
                        href={
                          "https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=" +
                          item.Tracking
                        }
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {item.Tracking}
                      </a>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Container>
      </div>
    );
  }
}

export default Returns;
