import React from "react";

class HOPR extends React.Component {
  constructor(props) {
    super(props);
    this.state = { apiResponse: "" };
    this.state = { jsonObject: "" };
  }

  callAPI() {
    fetch("https://odme-backend.herokuapp.com/api/hopr_shipments")
      .then((res) => res.text())
      .then((res) => this.setState({ apiResponse: res }));
  }

  UNSAFE_componentWillMount() {
    this.callAPI();
  }

  convertToObject(string) {
    try {
      if (typeof string !== "undefined") {
        this.setState({ jsonObject: JSON.parse(string).response });
      } else {
        this.jsonObject = null;
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  componentDidMount() {
    try {
      if (typeof string !== "undefined") {
        this.setState({
          jsonObject: JSON.parse(this.state.apiResponse).response,
        });
      } else {
        this.jsonObject = null;
      }
    } catch (err) {
      console.log(err.message);
    }
  }
  //   var t = this.convertToObject();
  //   console.log(t);
  render() {
    //
    //var tt = JSON.parse(this.state.apiResponse.data);
    this.convertToObject(this.state.apiResponse);
    if (this.state.jsonObject === null || this.state.jsonObject === " ") {
      console.log("returned null");
      return (
        <div className="w-full h-screen py-[300px] text-white">
          <p>nothing.</p>;
        </div>
      );
    } else {
      return (
        <div className="w-full py-40 ">
          <p className="text-2xl text-white text-2md text-center">
            HOPR Order List:
          </p>
          <div className="text-white text-sm grid-cols-7 min-w-[400px] max-w-[2000px] h-full py-10 px-20 grid items-center justify-center">
            <div className="px-10">Order ID:</div>
            <div>CustomerID:</div>
            <div>Customer:</div>
            <div>Venue:</div>
            <div>Order Amount:</div>
            <div>Order Date:</div>
            <div>Tracking Number:</div>

            <div className="text-white text-sm w-full max-h-[1000px] h-screen col-span-7 overflow-y-auto">
              {this.state.jsonObject &&
                this.state.jsonObject.map((obj) => (
                  <div className="grid grid-cols-7 text-sm min-w-[500px] h-[40px] items-start justify-start shadow-md shadow-gray-300 bg-green-500 p-4 m-4 rounded-lg cursor-pointer hover:bg-blue-500">
                    <div>{obj.OrderID}</div>
                    <div>{obj.CustomerID}</div>
                    <div>{obj.CustomerName}</div>
                    <div>{obj.CustomerVenue}</div>
                    <div>{obj.OrderAmount}</div>
                    <div>
                      {new Date(obj.OrderDate).toISOString().split("T")[0]}
                    </div>
                    <div>
                      <a
                        href={
                          "https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=" +
                          obj.OrderTrackingNumber
                        }
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {obj.OrderTrackingNumber}
                      </a>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      );
    }
  }
}

export default HOPR;
