import GetShipments from "../context/GetShipments";
import React, { useState, useEffect } from "react";
import { Container, Table, InputGroup } from "reactstrap";
import Form from "react-bootstrap/Form";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";


function Shipments() {
  const [search, setSearch] = useState("");
  var x = GetShipments();
  if (x === null || x === " ") {
    console.log("returned null");
    return (
      <div className="w-full h-full py-[300px] text-white">
        <p>nothing.</p>;
      </div>
    );
  } else {
    return (
      <div className="py-40 w-full h-full max-w-[1000] text-center z-1">
        <Container>
          <h1 className="text-white text-center mt-4">Shipments List</h1>
          <Form>
            <InputGroup className="my-3">
              <Form.Control
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search Shipments"
              />
            </InputGroup>
          </Form>
          <Table striped bordered hover>
            <thead>
              <tr className="text-white">
                <th>Serial Number</th>
                <th>Venue</th>
                <th>Status</th>
                <th>Ship Date</th>
                <th>Arrival Date</th>
                <th className="">Tracking Number</th>
              </tr>
            </thead>

            <tbody>
              {x
                .filter((item) => {
                  return search.toLowerCase() === ""
                    ? item 
                    : item.VenueName.toLowerCase().includes(search.toLowerCase()) ||
                    item.ODSN.toLowerCase().includes(search.toLowerCase());
                })
                .map((item) => (
                  <tr
                    key={item.id}
                    className="text-white bg-gray-400"
                    class="even:bg-white odd:bg-gray-400"
                  >
                    <td>{item.ODSN}</td>
                    <td>{item.VenueName}</td>
                    
                    <td>{item.Status}</td>
                    <td>{item.ShipDate.split("T")[0]}</td>
                    <td>{item.ArrivalDate.split("T")[0]}</td>
                    <td>
                      <a
                        href={
                          "https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=" +
                          item.TrackingNumber
                        }
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {item.TrackingNumber}
                      </a>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Container>
      </div>
    );
  }
}

export default Shipments;

