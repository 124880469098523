import React from "react";
import NavBar from "./components/NavBar";
import Account from "./components/Account";
import Home from "./components/Home";
import GameCenter from "./components/GameCenter";
import Login from "./components/Login";
import Contact from "./components/Contact";
import Downloads from "./components/Downloads";
import Shipments from "./components/Shipments";
import Returns from "./components/Returns";
import EmployeeNavBar from "./components/EmployeeNavBar";
import HOPR from "./components/HOPR";

import { Route, Routes, Navigate } from "react-router-dom";
import { AuthContextProvider } from "./context/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useState } from "react";
import "./index.css";

function App() {
  const auth = getAuth();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  onAuthStateChanged(auth, (users) => {
    setIsLoggedIn(!!users);
  });
  /* backgroundImage: `url(${background})`, */
  return (
    <div className="">
      <NavBar />
      <div className="row-2 col-1 items-start justify-start enav-menu bg-[#0a192f]">
        {isLoggedIn && <EmployeeNavBar />}
      </div>
      <div className="full-height bg-[#0a192f]">
        <AuthContextProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/gamecenter" element={<GameCenter />} />
            <Route path="/home" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/contact" element={<Contact />} />
            <Route
              path="/shipments"
              element={
                <ProtectedRoute>
                  <Shipments />
                </ProtectedRoute>
              }
            />
            <Route
              path="/hopr"
              element={
                <ProtectedRoute>
                  <HOPR />
                </ProtectedRoute>
              }
            />
            <Route
              path="/returns"
              element={
                <ProtectedRoute>
                  <Returns />
                </ProtectedRoute>
              }
            />

            <Route
              path="/account"
              element={
                <ProtectedRoute>
                  <Account />
                </ProtectedRoute>
              }
            />
            <Route
              path="/downloads"
              element={
                <ProtectedRoute>
                  <Downloads />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </AuthContextProvider>
      </div>
    </div>
  );
}

export default App;
