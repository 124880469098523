import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { auth } from "../firebase";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { signIn } = UserAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    console.log(error);
    try {
      await signIn(email, password);
      navigate("/account");
    } catch (e) {
      setError(e.message);
      console.log(e.message);
    }
  };
  return (
    <div className="max-w-[700px] h-screen mx-auto p-4">
      <div className="my-20">
        <h1 className="text-2xl font-bold py-20 text-orange-600">
          Sign in to your account
        </h1>
      </div>
      <form onSubmit={handleSubmit} className="">
        <div className="flex flex-col ">
          <label className="py-2 font-medium text-orange-400">
            Email Address
          </label>
          <input
            onChange={(e) => setEmail(e.target.value)}
            className="border p-3 rounded-md"
            type="email"
          />
        </div>
        <div className="flex flex-col py-2">
          <label className="py-2 font-medium text-orange-400">Password</label>
          <input
            onChange={(e) => setPassword(e.target.value)}
            className="border p-3 rounded-md"
            type="password"
          />
        </div>
        <div className="flex items-end justify-end">
          <button className="borderf border-orange-500 bg-orange-600 hover:bg-orange-500 w-[35%] p-4 my-2 text-white rounded-md">
            Sign In
          </button>
        </div>
      </form>
    </div>
  );
};

export default Login;
