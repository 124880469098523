import { FaBars, FaTimes } from "react-icons/fa";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/od_dot_me.png";
import TeamViewer from "../assets/tv_test.png";
import AnyDesk from "../assets/anydesk.png"
import HOPR_Logo from "../assets/hopr_logo.jpg"
import { getAuth, onAuthStateChanged } from "firebase/auth";
import "../index.css";

const NavBar = () => {
  const auth = getAuth();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState("");
  onAuthStateChanged(auth, (user) => {
    setIsLoggedIn(!!user);
    setUser(user);
  });
  const [nav, setNav] = useState(false);
  //const handleClick = () => setNav(!nav);

  const handleClick = () => {
    setNav((nav) => !nav)
  }


  return (
    <div className="justify-between w-full items-center flex h-24 p-20 mx-auto bg-[#0a192f] text-gray-300 z-9">
      

      {/* menu */}
      {isLoggedIn ? (
        <p className="text-white p-4 flex items-end justify-end">
          User: {user && user.email}
        </p>
      ) : (
        <ul className="hidden md:flex px-4 cursor-pointer mt-5 my-20">
          <li className="min-w-[200px]">
            <a
              href="https://www.teamviewer.com/en-us/teamviewer-automatic-download/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                className="md:w-70 lg:w-140 md:h-15 lg:h-30"
                src={TeamViewer}
                alt=""
                style={{ width: 140, height: 30 }}
              />
            </a>
          </li>
          <li className="min-w-[200px]">
            <a
              href="https://anydesk.com/en"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                className="md:w-70 lg:w-140 md:h-15 lg:h-30"
                src={AnyDesk}
                alt=""
                style={{ width: 140, height: 30 }}
              />
            </a>
          </li>
          
          <li className="hover:text-orange-600 ">
            <Link to="/home">Home</Link>
          </li>
          <li className="hover:text-orange-600 ">
            <Link to="/gamecenter">Game Center</Link>
          </li>
          <li className="hover:text-orange-600 ">
            <Link to="/contact">Contact</Link>
          </li>
          <li> - </li>
          <li className="hover:text-orange-600 ">
            {isLoggedIn ? (
              <Link to="/account">Log out</Link>
            ) : (
              <Link to="/login">Login</Link>
            )}
          </li>
        </ul>
      )}
      {/* hamburger */}
      <div onClick={handleClick} className="fixed md:hidden z-10 cursor-pointer">
      {!nav ? <FaBars /> : <FaTimes />}
     </div>

      {/* mobile menu */}
      <ul
        className={
          !nav
            ? "hidden"
            : "fixed top-50 left-0 flex flex-col bg-[#0a192f] items-center cursor-pointer w-[60%] "
        }
      >
        <li className="py-6 text-4xl">
          <Link onClick={handleClick} to="/home">
            Home
          </Link>
        </li>
        <li className="py-6 text-4xl">
          <Link
            onClick={handleClick}
            to="gamecenter"
            smooth={true}
            duration={500}
          >
            Game Center
          </Link>
        </li>
        <li className="py-6 text-4xl">
          <Link to="/contact">Contact</Link>
        </li>
        <li className="py-6 text-4xl">
          <Link to="/login">Log In</Link>
        </li>
      </ul>
      {/* social icons */}
      </div>
  );
};

export default NavBar;
