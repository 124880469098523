import { Link } from "react-router-dom";
import React from "react";
import "../index.css";

const EmployeeNavBar = () => {
  return (
    <div className="absolute text-center w-full h-[50px] mt-[80px] flex items-center justify-center bg-gray-700">
      {/* container */}
      <div>
        {/* items */}
        <div className="p-4 rounded-md flex items-center justify-center">
          <Link to="/dashboard" className="enav-btn">
            Dashboard
          </Link>
          |
          <Link to="/account" className="enav-btn">
            Account
          </Link>
          |
          <Link to="/shipments" className="enav-btn">
            Shipments
          </Link>
          |
          <Link to="/returns" className="enav-btn">
            Returns
          </Link>
          |
          <Link to="/downloads" className="enav-btn">
            Downloads
          </Link>
          |
          <Link to="/hopr" className="enav-btn">
            HOPR
          </Link>
        </div>
      </div>
    </div>
  );
};

export default EmployeeNavBar;
