// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBpBneZdhLbmxGGziT4QY_OHXp03aRU2PI",
  authDomain: "orangedoor-me.firebaseapp.com",
  projectId: "orangedoor-me",
  storageBucket: "orangedoor-me.appspot.com",
  messagingSenderId: "694657710161",
  appId: "1:694657710161:web:fc16d3c8fe3a11fa09e070",
};

// Initialize Firebase
//const app = initializeApp(firebaseConfig);
firebase.initializeApp(firebaseConfig);
//export const auth = getAuth(app);
export const auth = firebase.auth();
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
export default firebase;
